import { auth } from '../../firebase';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useState } from "react";
import logoGif from "../../assets/images/logoGif.gif";
import GoogleButton from 'react-google-button'
import PasswordChecklist from "react-password-checklist"
import LoginIcon1 from "../../assets/images/LoginIcon1.png";
import LoginIcon2 from "../../assets/images/LoginIcon2.png";
import Swal from "sweetalert2";

import { Helmet } from "react-helmet";
import { useEffect } from 'react';

const CreateAccount = () => {
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [passAgain, setPassAgain] = useState("");
    const [validPass, setValidPass] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [createSuccess, setCreateSuccess] = useState(true);
    const [disable, setDisable] = useState(true);

    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            if (disable === false) {
                createAccountEmailPassword();
            }
        }
    }

    const createAccountEmailPassword = async () => {
        await createUserWithEmailAndPassword(auth, email, pass)
            .then(() => {
                // Signed in 
                //const user = userCredential.user;
                window.location.href = "/signup";
                // ...
            })
            .catch(() => {
                //const errorCode = error.code;
                //const errorMessage = error.message;
                setCreateSuccess(false);
            });
    }

    useEffect(() => {
        if (email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(email)) {
                setValidEmail(true);
            } else {
                setValidEmail(false);
            }
        } else {
            setValidEmail(false);
        }
    }, [email]);

    useEffect(() => {
        if (validEmail && validPass === true) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [validEmail, validPass]);


    const createGooglePopUp = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider)
            .then((result) => {
                window.location.href = "/signup";
            }).catch((error) => {
                // Handle Errors here.
                //const errorCode = error.code;
                //const errorMessage = error.message;
                // The email of the user's account used.
                //const email = error.customData.email;
                // The AuthCredential type that was used.
                GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }
    return (
        <>
            <Helmet>
                <title>
                    MyInfluency | Login
                </title>
            </Helmet>
            <div className="grid grid-cols-1 md:grid-cols-3 justify-between items-center h-screen">
                <div className="hidden md:flex md:flex-col h-full">
                    <img src={LoginIcon1} alt="Login Image1" className="md:w-1/2" />
                </div>

                <div className="flex flex-col p-20 bg-white rounded-xl md:shadow-2xl 2xl:mx-20">
                    <img src={logoGif} alt="My Influency" className="object-scale-down w-64 mx-auto mb-8" />
                    {(createSuccess)
                        ? <></>
                        : <h2 className="text-red-500 text-center font-poppins text-base">Invalid password/username! Try again!</h2>
                    }
                    <div className="flex flex-col ">
                        <label className="text-left px-4 py-2 font-poppins text-black" htmlFor="email">Email:</label>
                        <input className="p-2 mb-4 border-b-2 border-mi-green" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="your_email@gmail.com" required="required" onKeyPress={handleKeypress}></input>
                        <label className="text-left px-4 py-2 font-poppins text-black" htmlFor="password">Password:</label>
                        <input className="p-2 border-b-2 border-mi-green" value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeholder="********" onKeyPress={handleKeypress}></input>
                        {(!pass)
                            ? <></>
                            : <label className="text-left px-4 py-2 font-poppins text-black" htmlFor="password">Re-Enter Password:</label>
                        }
                        {(!pass)
                            ? <></>
                            : <input
                                className="p-2 border-b-2 border-mi-green"
                                value={passAgain}
                                onChange={(e) => setPassAgain(e.target.value)}
                                type="password"
                                placeholder="********"
                                onKeyPress={handleKeypress}>
                            </input>
                        }
                        {(!pass)
                            ? <></>
                            : <PasswordChecklist
                                className='text-sm p-4'
                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                minLength={8}
                                value={pass}
                                valueAgain={passAgain}
                                onChange={setValidPass}
                            />
                        }
                        <button
                            className={`
                                m-2 mt-8 border-0 rounded-lg p-4 cursor-pointer text-white font-poppins shadow-2xl 
                                bg-mi-green transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-200
                                ${disable ? 'opacity-50 cursor-not-allowed' : 'hover:bg-light-green'}
                            `}
                            onClick={createAccountEmailPassword}
                            disabled={disable}>
                            Sign Up
                        </button>
                    </div>
                    <div className="mx-auto text-gray-500 font-poppins">or</div>
                    <div className="mx-auto my-4">
                        <GoogleButton type='dark' onClick={createGooglePopUp} />
                    </div>
                    <div className="flex flex-row gap-2 bg-white rounded-xl w-fit p-4">
                        <h2 className="text-center text-base text-gray-600 font-poppins">Already have an account?</h2>
                        <a href="/signin" className="text-center text-mi-green underline">Sign In</a>
                    </div>
                </div>

                <div className="hidden md:flex md:flex-col h-full justify-end items-end">
                    <img src={LoginIcon2} alt="Login Image2" className="md:w-1/2" />
                </div>
            </div>
        </>
    );
}
export default CreateAccount;