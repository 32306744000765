import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import peopleIcon from "../../assets/images/people.png";
import plusIcon from "../../assets/images/plus_icon.svg";
import blueFilledPlusIcon from "../../assets/images/bluefilled_plus_icon.svg";
import ProfilePic from "../ProfilePic";
import Modal from "../Modal";
import TWSearch from "../TWSearch";
import TWButton from "../TWButton";
import TWSelect from "../TWSelect";
import TWDropDown from "../TWDropDown";
import PostContainer from "./PostContainer";
import Spinner from "../Spinner";
import { Rating } from "@mui/material";
import VideoContainer from "./VideoContainer";
import { useMediaQuery } from "react-responsive";
import CreateListModal from "./CreateListModal";


const ProfileModal = (props) => {
    const [campaignSelect, setCampaignSelect] = useState("");
    const [modalData, setModalData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fullBio, setFullBio] = useState(false);
    const [bioOverflow, setBioOverflow] = useState(false);
    const [openPlusIcon, setOpenPlusIcon] = useState(false);
    const [showPlusIcon, setShowPlusIcon] = useState(false);
    const [showRecentPosts, setShowRecentPosts] = useState(true);
    const [showRates, setShowRates] = useState(false);
    const [showData, setShowData] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [savedLists, setSavedLists] = useState(props.savedList);
    const [activeLists, setActiveLists] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);

    // modal states
    const [createModal, setCreateModal] = useState(false);

    const bioRef = useRef(null);

    const csrf_token = Cookies.get("csrftoken");

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    useEffect(() => {
        const getProfileModalData = async () => {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/get_profile_modal_data`;
            const requestMetaData = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(props.uid),
            };
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        const data = result.data;
                        setModalData(data);
                    }
                });
        }
        getProfileModalData();
    }, []);

    useEffect(() => {
        if (loading) {
            const queueOffer = async () => {
                const token = await auth.currentUser.getIdToken();
                const url = `${process.env.REACT_APP_DJANGO_API}/queue_offer`;
                const requestMetaData = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        influencerid: modalData.uid,
                        campaignid: campaignSelect,
                    }),
                };
                await fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: result.alert,
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            ReactGA.send({
                                hitType: "add_to_cart",
                                currency: "USD",
                                value: modalData.price,
                                items: [{
                                    item_id: modalData.uid,
                                    item_name: modalData.name,
                                    price: modalData.price,
                                }]
                            })
                            Swal.fire({
                                icon: "success",
                                title: "Done!",
                                text: result.alert,
                                confirmButtonColor: "#7BBA83",
                            });
                            const data = result.data;
                            props.setCartInfluList(data);
                            setLoading(false);
                        }
                    });
            };
            queueOffer();
        }
    }, [loading]);

    const getActiveList = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_active_list/${modalData.uid}`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                    setActiveLists(data);
                }
            });
    }

    const removeInfluencerFromList = async (listID) => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/remove_influencer_from_list/${modalData.uid}/${listID}`;
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                }
            });
    }

    const saveInfluencerToList = async (selectedCategory) => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/save_influencer_to_list`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                listIds: selectedCategory,
                influencerId: modalData.uid,
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                }
            });
    }

    useLayoutEffect(() => {
        if (bioRef.current && !fullBio) {
            setBioOverflow(bioRef.current.offsetHeight < bioRef.current.scrollHeight);
        }
    });

    // Toggle function for plus icon
    const togglePlusIcon = () => {
        if (openPlusIcon) {
            setOpenPlusIcon(false);
            setTimeout(() => setShowPlusIcon(false), 300);
        } else {
            setShowPlusIcon(true);
            setOpenPlusIcon(true);
            getActiveList();

        }
    };

    // Toggle function for showing mobile data (Recent Posts, Rates, Data)
    const toggleMobileInfo = (info) => {
        if (info === "recentPosts") {
            setShowRecentPosts(true);
            setShowRates(false);
            setShowData(false);
        } else if (info === "rates") {
            setShowRecentPosts(false);
            setShowRates(true);
            setShowData(false);
        } else if (info === "data") {
            setShowRecentPosts(false);
            setShowRates(false);
            setShowData(true);
        } else {
            setShowRecentPosts(true);
            setShowRates(false);
            setShowData(false);
        }
    }

    const onChangeCategory = e => {
        const checkedCategoryId = e.target.value;
        const isChecked = e.target.checked;
        if (isChecked) {
            setCategoryList(prevList => prevList.concat(checkedCategoryId));
            saveInfluencerToList(checkedCategoryId);

        } else {
            setCategoryList(categoryList.filter(elem => elem !== checkedCategoryId));
            removeInfluencerFromList(checkedCategoryId);
        }
    }


    useEffect(() => {
        const combinedData = [
            ...savedLists.map(list => ({
                value: list.list_id,
                label: list.list_title,
                isActive: false,
            })),
        ];

        const updatedDropdownData = combinedData.map(item => ({
            ...item,
            isActive: activeLists.some(activeItem => activeItem.save_list_id === item.value)
        }));

        setDropdownData(updatedDropdownData);
    }, [activeLists]);


    const toggleItemActive = (index) => {
        setDropdownData(currentData =>
            currentData.map((item, idx) =>
                idx === index ? { ...item, isActive: !item.isActive } : item
            )
        );
    };

    const handleNewListCreated = (newList) => {
        const updatedLists = [
            ...savedLists,
            {
                list_id: newList.save_list_id,
                list_title: newList.save_list_title,
            },
        ];

        setSavedLists(updatedLists);

        props.handleSavedListsUpdate(updatedLists);

        setDropdownData((prevList) => [
            ...prevList,
            { value: newList.save_list_id, label: newList.save_list_title, isActive: false },
        ]);
    };

    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                {!modalData
                    ? <div className="w-full h-fit flex flex-col"><Spinner /></div>
                    : <div className="flex flex-col rounded-lg w-full h-full md:overflow-clip">

                        {/*body*/}
                        {/* NON-MOBILE VERSION */}
                        {!isSmallScreen && (
                            <div className="flex flex-col md:flex-row w-full max-h-full md:overflow-clip">
                                {/* LEFT SIDE (profile data) */}
                                <div className="flex flex-col items-center gap-1 md:w-96 overflow-auto py-20 border-neutral-300 bg-mi-light-blue scrollbar">
                                    <div className="w-full flex justify-center">
                                        <div className="w-52">
                                            <ProfilePic border image={modalData.profile_pic} />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-3">
                                        <div className="flex flex-row gap-2">
                                            <Rating readOnly value={modalData.rating ? modalData.rating : 0} precision={0.1} size='large' />
                                            <p className="text-gray-800 text-base flex justify-center items-center font-poppins font-bold">( {modalData.num_ratings} )</p>
                                        </div>
                                    </div>
                                    <div className="md:hidden flex flex-row gap-2 justify-center mt-3">
                                        <Rating readOnly value={modalData.rating ? modalData.rating : 0} precision={0.1} />
                                        <p className="text-gray-800 font-poppins">({modalData.num_ratings})</p>
                                    </div>
                                    <div className="md:hidden flex flex-col items-center">
                                        <div className="text-center text-lg w-full font-semibold text-mi-title mb-1 mt-3">
                                            About
                                        </div>
                                        <p className="text-gray-800 font-poppins">
                                            {modalData.bio}
                                        </p>
                                    </div>
                                    <div className="md:hidden justify-center md:justify-start py-2 flex flex-col">
                                        <p className="text-xl Dosis font-semibold text-mi-title mt-3">Pricing</p>
                                        <div className="flex flex-col col-span-1 md:flex-row md:overflow-clip overflow-auto">
                                            <table className="w-full auto border-collapse font-poppins text-gray-800 text-left border border-gray-400 mt-3 text-md">
                                                <thead>
                                                    <tr>
                                                        <th className="border border-gray-300 pl-1 bg-zinc-200 font-semibold">Media Type</th>
                                                        <th className="border border-gray-300 pl-1 bg-zinc-200 font-semibold">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="border border-gray-300 pl-1 bg-white">Instagram Feed Post</td>
                                                        <td className="border border-gray-300 pl-1 bg-white">${modalData.price_feed_posts ? modalData.price_feed_posts : "N/A"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-gray-300 pl-1 bg-white">Instagram Reel</td>
                                                        <td className="border border-gray-300 pl-1 bg-white">${modalData.price_reels ? modalData.price_reels : "N/A"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-gray-300 pl-1 bg-white">Instagram Video</td>
                                                        <td className="border border-gray-300 pl-1 bg-white">${modalData.price_videos ? modalData.price_videos : "N/A"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-gray-300 pl-1 bg-white">Instagram Story</td>
                                                        <td className="border border-gray-300 pl-1 bg-white">${modalData.price_stories ? modalData.price_stories : "N/A"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="py-2 flex flex-col">
                                        <h1 className="text-left text-xl w-full text-mi-blue font-extrabold font-poppins mt-4 mb-4">Performace Data</h1>
                                        <span className="text-gray-800 font-poppins">Avg Engagement: {modalData.avg_engagement_rate}%</span>
                                        <span className="text-gray-800 font-poppins">Avg Reach: {modalData.avg_reach}</span>
                                        <span className="text-gray-800 font-poppins">Avg Impressions: {modalData.avg_impressions}</span>
                                        <span className="text-gray-800 font-poppins">Response Rate: {modalData.response_rate}%</span>
                                    </div>
                                    <div>
                                        <div className="py-2 flex flex-col">
                                            <h1 className="text-left text-xl w-full text-mi-blue font-extrabold font-poppins mt-4 mb-4">Audience Data</h1>
                                            <p className="w-full font-poppins mb-1 text-base text-neutral-600 font-bold">Location Breakdown</p>
                                            {modalData.location_insights.map((locations, index) => {
                                                return <span key={index} className="text-gray-800 font-poppins">{locations.location}: {locations.value}%</span>
                                            })}
                                        </div>
                                        <div className="py-2 flex flex-col">
                                            <p className="w-full font-poppins mb-1 text-base font-bold text-neutral-600">Gender Breakdown</p>
                                            <span key={0} className="text-gray-800 font-poppins">{"Male"}: {modalData["gender_insights"]["male"]}%</span>
                                            <span key={1} className="text-gray-800 font-poppins">{"Female"}: {modalData["gender_insights"]["female"]}%</span>
                                            <span key={2} className="text-gray-800 font-poppins">{"Unspecified"}: {modalData["gender_insights"]["non_binary"]}%</span>
                                        </div>
                                    </div>
                                </div>

                                {/* RIGHT SIDE (Bio and Previous posts) */}
                                <div className="md:w-full flex flex-col gap-3 p-5 overflow-auto scrollbar bg-slate-50">
                                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white fixed top-4 right-4 z-40" data-modal-hide="defaultModal" onClick={() => props.setShowModal(false)} >
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </button>
                                    <div className="md:flex md:flex-col gap-2 hidden">

                                        <div className="grid grid-cols-12 flex flex-col flex-wrap mt-12">
                                            <div className="justify-center md:justify-start h-full flex flex-col col-span-5">
                                                <div className="h-full p-2 flex flex-col ml-2">
                                                    <div className="flex flex-col h-full w-full">

                                                        <p className="text-3xl font-extrabold inline-block items-center flex flex-row">
                                                            <div className="relative">
                                                                {modalData.name}
                                                                <button onClick={() => {
                                                                    togglePlusIcon();
                                                                }}>
                                                                    <img alt="Plus Button" src={plusIcon} className="scale-125 ml-3"></img>
                                                                </button>
                                                                <div className={`${!openPlusIcon ? "h-0" : "h-[25rem]"} absolute z-50 md:w-72 duration-300 overflow-y-auto md:scrollbar bg-white rounded-xl shadow-xl divide-y divide-solid`}>
                                                                    {(showPlusIcon)}
                                                                    <h3 className="p-1 flex justify-center">Save to List</h3>
                                                                    <div className="flex flex-col items-center">
                                                                        <div className="my-3 w-full flex justify-center">
                                                                            <TWSearch url="explore">Search</TWSearch>
                                                                        </div>
                                                                        <div className="overflow-y-auto h-56 w-full scrollbar">
                                                                            <label className="w-full">
                                                                                <input type="checkbox" className="hidden peer" />

                                                                                <div className="overflow-y-auto h-56 w-full scrollbar">
                                                                                    <ul className="list-none">
                                                                                        {dropdownData.map((item, index) => (
                                                                                            <li key={index}>
                                                                                                <label className={`flex mb-2 ml-4 cursor-pointer p-2 text-xs transition-colors hover:bg-blue-100 ${item.isActive ? 'bg-blue-200' : 'bg-white'}`}>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={item.isActive}
                                                                                                        value={item.value}
                                                                                                        className="cursor-pointer"
                                                                                                        onClick={() => toggleItemActive(index)}
                                                                                                        onChange={onChangeCategory}
                                                                                                        readOnly
                                                                                                    />
                                                                                                    <span className="ml-3">{item.label}</span>
                                                                                                </label>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>

                                                                            </label>
                                                                        </div>
                                                                        <button className="flex flex-row w-full justify-start shadow-t-lg items-center gap-4 h-16 hover:bg-blue-100" onClick={() => { togglePlusIcon(); setCreateModal(true) }}>
                                                                            <img alt="Create List" src={blueFilledPlusIcon} className="scale-125 ml-3" />
                                                                            <h3 className="font-bold">Create List</h3>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </p>
                                                        <span className="text-gray-800 font-poppins mt-3"><a href={`https://instagram.com/${modalData.ig_handle}`} className="inline text-lg mt-0 text-mi-blue font-bold">@{modalData.ig_handle}</a></span>

                                                        <div className="grid-cols-1 mt-3 inline-flex flex-wrap gap-2">
                                                            <span className="text-gray-800 text-lg font-bold rounded-full px-11 py-2 inline-flex bg-blue-100 uppercase mt-3 font-poppins">{(modalData.category === "Other") ? modalData.other_category : modalData.category}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="justify-center md:justify-start py-2 flex flex-col col-span-6">
                                                <div className="px-2 flex flex-col col-span-1 md:flex-row md:overflow-clip rounded-2xl h-full text-lg shadow-xl p-4 bg-white overflow-auto">
                                                    <table className="w-full auto border-collapse font-poppins text-gray-800 text-left bg-white mt-1 text-md">
                                                        <thead>
                                                            <tr>
                                                                <th className="pl-7 text-mi-blue font-semibold">Media Type</th>
                                                                <th className="pl-7 text-mi-blue font-semibold">Rates</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="pl-7 bg-white">Feed Posts</td>
                                                                <td className="pl-7 bg-white">${modalData.price_feed_posts ? modalData.price_feed_posts : "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pl-7 bg-white">Reels</td>
                                                                <td className="pl-7 bg-white">${modalData.price_reels ? modalData.price_reels : "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pl-7 bg-white">Videos</td>
                                                                <td className="pl-7 bg-white">${modalData.price_videos ? modalData.price_videos : "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pl-7 bg-white">Stories</td>
                                                                <td className="pl-7 bg-white">${modalData.price_stories ? modalData.price_stories : "N/A"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <p className="text-xl font-poppins font-bold px-4 text-mi-black mb-4">About</p>
                                        <p ref={bioRef} className={`text-gray-800 px-4 font-poppins mb-4 ${!fullBio ? "line-clamp-4" : ""}`}>
                                            {modalData.bio}
                                        </p>
                                        {/* tags */}
                                        <div className="grid-cols-1 mt-3 inline-flex flex-wrap gap-2 px-4">
                                            {modalData.tags.map(tag => <span className="text-gray-800 font-bold rounded-full px-8 py-1.5 inline-flex bg-mi-tag-blue lowercase font-poppins">{tag.name}</span>)}
                                        </div>
                                        {bioOverflow &&
                                            <div className="flex flex-row justify-end px-4">
                                                <TWButton secondary onClick={() => setFullBio(!fullBio)}>{fullBio ? "Hide" : "Read more"}</TWButton>
                                            </div>
                                        }
                                    </div>

                                    {/* previous posts */}
                                    <p className="text-xl font-poppins font-bold px-4 text-mi-black mt-4 mb-4 flex">Recent Posts</p>
                                    <div className="rounded-xl bg-slate-50">
                                        <div className="w-full flex flex-col md:flex-row md:flex-wrap justify-center gap-4 rounded-xl p-2 overflow-auto scrollbar">
                                            {modalData.posts.map((post, index) => {
                                                if (post.post_type[0] === 'I')
                                                    return <div key={index} className="md:w-[31.4%]"><PostContainer data={post} /></div>
                                                // if (post.post_type[0] === 'V')
                                                    // return <div key={index} className="md:w-[62.8%]"><VideoContainer data={post} profilePic={modalData.profile_pic} igHandle={modalData.ig_handle} /></div>
                                                if (post.post_type[0] === 'R')
                                                    return <div key={index} className="md:w-[31.4%]"><VideoContainer data={post} profilePic={modalData.profile_pic} igHandle={modalData.ig_handle} /></div>
                                            })}
                                        </div>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex flex-col justify-end items-end h-full">
                                        <div className="flex flex-nowrap justify-center md:justify-end gap-4">
                                            <div className="mt-1 py-1">
                                                <TWSelect onChange={e => setCampaignSelect(e.target.value)}>
                                                    <option value="">Select A Campaign</option>
                                                    {props.campaignList.map((campaign, index) => (
                                                        <option key={index} value={campaign.campaign_id}>{campaign.campaign_title}</option>
                                                    ))}
                                                </TWSelect>
                                            </div>

                                            <div className="py-1">
                                                <TWButton md disabled={!campaignSelect || loading} loading={loading} onClick={() => setLoading(true)}>
                                                    Queue Proposal
                                                </TWButton>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}

                        {/*body*/}
                        {/* MOBILE VERSION */}
                        {isSmallScreen && (
                            <div className="flex flex-col w-full min-h-full overflow-auto scrollbar">
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white fixed top-4 right-4 z-40" data-modal-hide="defaultModal" onClick={() => props.setShowModal(false)} >
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                                <div className="w-full flex justify-center mt-16 gap-6">
                                    <div className="w-36 mb-1">
                                        <ProfilePic border image={modalData.profile_pic} />
                                        <div className="relative">

                                            <button onClick={() => {
                                                togglePlusIcon();
                                            }}>
                                                <img alt="Plus Button" src={blueFilledPlusIcon} className="absolute scale-150 ml-3 bottom-6 right-4" onClick={togglePlusIcon} />
                                            </button>
                                        </div>

                                    </div>
                                    <div className={`${!openPlusIcon ? "opacity-0 scale-0" : "opacity-100 scale-100"} absolute transition duration-300 ease-in-out p-5 z-50 bottom-0 w-full h-[30rem] scrollbar bg-white rounded-xl shadow-xl divide-y divide-solid`}>
                                        {(showPlusIcon)}
                                        <div className="flex flex-row">
                                            <h2 className="p-3">Save to List</h2>
                                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white top-4 right-4 z-40" data-modal-hide="defaultModal" onClick={togglePlusIcon} >
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                            </button>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <div className="my-3 w-full flex justify-center">
                                                <TWSearch shadow url="explore">Search</TWSearch>
                                            </div>
                                            <div className="overflow-y-auto h-[18rem] w-full scrollbar">
                                                <label className="w-full">
                                                    <input type="checkbox" className="hidden peer" />
                                                    <div className="scrollbar">
                                                        <ul className="list-none">
                                                            {dropdownData.map((item, index) => (
                                                                <li key={index}>
                                                                    <label className={`flex mb-2 ml-4 cursor-pointer p-2 text-xs transition-colors hover:bg-blue-100 ${item.isActive ? 'bg-blue-200' : 'bg-white'}`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={item.isActive}
                                                                            value={item.value}
                                                                            className="cursor-pointer"
                                                                            onClick={() => toggleItemActive(index)}
                                                                            onChange={onChangeCategory}
                                                                            readOnly
                                                                        />
                                                                        <span className="ml-3">{item.label}</span>
                                                                    </label>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </label>
                                            </div>
                                            <button className="flex flex-row w-full justify-start items-center gap-4 h-[3rem] sticky top-[100vh]" onClick={() => { togglePlusIcon(); setCreateModal(true) }}>
                                                <img alt="Create List" src={blueFilledPlusIcon} className="scale-125 ml-3" />
                                                <p className="font-bold">Create List</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <div className="text-center font-bold text-base text-grey-700 mt-5">
                                            {modalData.name}
                                        </div>
                                        <span className="text-gray-800 text-sm mb-4"><a href={`https://instagram.com/${modalData.ig_handle}`} className="inline text-mi-blue font-bold">@{modalData.ig_handle}</a></span>
                                        <div className="flex flex-row gap-2 mb-2 justify-center">
                                            <Rating readOnly value={modalData.rating ? modalData.rating : 0} precision={0.1} />
                                            <p className="text-gray-800 text-sm">({modalData.num_ratings})</p>
                                        </div>
                                        {/* <TWButton md onClick={() => setLoading(true)}>
                                            + Add to List
                                        </TWButton> */}
                                        <div className="w-[150px] mt-3 inline-flex gap-2 overflow-auto scrollbar">
                                            <span className="text-gray-800 text-sm font-bold rounded-full px-11 py-1.5 inline-flex bg-sky-100 uppercase">{(modalData.category === "Other") ? modalData.other_category : modalData.category}</span>

                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-evenly mt-7">
                                    <div className="font-bold flex flex-col items-center text-xl">
                                        {modalData.follower_count}
                                        <div className="font-bold text-gray-400 text-xs">Followers</div>
                                    </div>
                                    <div class="h-[50px] bg-gray-200 w-[2px]"></div>
                                    <div className="text-gray-800 flex flex-col items-center font-bold text-xl">
                                        {modalData.avg_engagement_rate}%
                                        <div className="font-bold text-gray-400 text-xs">Avg Engagement</div>
                                    </div>
                                    <div class="h-[50px]  bg-gray-200 w-[2px]"></div>
                                    <div className="text-gray-800 flex flex-col items-center font-bold text-xl">
                                        {modalData.avg_reach}
                                        <div className="font-bold text-gray-400 text-xs">Avg Reach </div>
                                    </div>
                                </div>
                                <div className="mt-7 mb-5">
                                    <p ref={bioRef} className={`text-gray-800 px-4 ${!fullBio ? "line-clamp-4" : ""}`}>
                                        {modalData.bio}
                                    </p>
                                    {bioOverflow &&
                                        <div className="flex flex-row justify-end px-4">
                                            <TWButton secondary onClick={() => setFullBio(!fullBio)}>{fullBio ? "Hide" : "Read more"}</TWButton>
                                        </div>
                                    }
                                    {/* tags here */}
                                    <div className="grid-cols-1 mt-3 inline-flex flex-wrap gap-2 px-3">
                                        {modalData.tags.map(tag => <span className="text-gray-800 font-bold rounded-full px-8 py-1.5 inline-flex bg-mi-tag-blue lowercase font-poppins">{tag.name}</span>)}
                                    </div>
                                </div>
                                <hr />

                                <div className="flex flex-row justify-evenly mt-5">
                                    <button onClick={() => toggleMobileInfo("recentPosts")} className={`${!showRecentPosts ? "text-gray-800" : "text-mi-blue underline underline-offset-8 decoration-2"} font-bold text-lg`}>Recent Posts</button>
                                    <button onClick={() => toggleMobileInfo("rates")} className={`${!showRates ? "text-gray-800" : "text-mi-blue underline underline-offset-8 decoration-2"} font-bold text-lg`}>Rates</button>
                                    <button onClick={() => toggleMobileInfo("data")} className={`${!showData ? "text-gray-800" : "text-mi-blue underline underline-offset-8 decoration-2"} font-bold text-lg`}>Data</button>

                                </div>
                                <div className="flex flex-col ">
                                    <div className={`${!showRecentPosts ? "hidden" : "w-full"}`}>
                                        {/* previous posts */}
                                        <div className="">
                                            <div className="w-full flex flex-row gap-4 rounded-xl p-2 overflow-x-auto">
                                                {modalData.posts.map((post, index) => {
                                                    if (post.post_type[0] === 'I')
                                                        return <div key={index} className="md:w-[31.4%]"><PostContainer data={post} /></div>
                                                    // if (post.post_type[0] === 'V')
                                                    //     return <div key={index} className="md:w-[31.4%]"><VideoContainer data={post} profilePic={modalData.profile_pic} igHandle={modalData.ig_handle} /></div>
                                                    if (post.post_type[0] === 'R')
                                                        return <div key={index} className="md:w-[31.4%]"><VideoContainer data={post} profilePic={modalData.profile_pic} igHandle={modalData.ig_handle} /></div>
                                                })}
                                            </div>
                                        </div>

                                    </div>
                                    <div className={`${!showRates ? "hidden" : "flex justify-center mt-10 mb-7"}`}>
                                        <div className="px-2 flex flex-col col-span-1 w-10/12 rounded-2xl h-full text-lg shadow-all-xl p-4 bg-white overflow-auto">
                                            <table className="w-full auto border-collapse font-poppins text-gray-800 text-left bg-white mt-1 text-md">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-7 text-mi-blue text-base font-semibold">Media Type</th>
                                                        <th className="pl-7 text-mi-blue text-base font-semibold">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-7 text-sm bg-white">Feed Posts</td>
                                                        <td className="pl-7 text-sm bg-white">${modalData.price_feed_posts ? modalData.price_feed_posts : "N/A"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-7 text-sm bg-white">Reels</td>
                                                        <td className="pl-7 text-sm bg-white">${modalData.price_reels ? modalData.price_reels : "N/A"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-7 text-sm bg-white">Videos</td>
                                                        <td className="pl-7 text-sm bg-white">${modalData.price_videos ? modalData.price_videos : "N/A"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-7 text-sm bg-white">Stories</td>
                                                        <td className="pl-7 text-sm bg-white">${modalData.price_stories ? modalData.price_stories : "N/A"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={`${!showData ? "hidden" : "flex justify-center mb-5"}`}>
                                        <div className="w-10/12 py-2 flex flex-col">
                                            <h1 className="text-lg flex flex-start text-gray-800 font-extrabold font-poppins mt-8 mb-1">Performace Data</h1>
                                            <div className="px-2 flex flex-col justify-center rounded-2xl h-full text-lg shadow-xl p-4 bg-white overflow-auto">
                                                <div className="flex justify-around">
                                                    <span className="text-gray-800 text-sm font-poppins">Avg Engagement: <span className="font-bold">{modalData.avg_engagement_rate}%</span></span>
                                                    <span className="text-gray-800 text-sm font-poppins">Avg Impressions: <span className="font-bold">{modalData.avg_impressions}</span></span>
                                                </div>
                                                <div className="flex justify-around">
                                                    <span className="text-gray-800 text-sm font-poppins">Avg Reach: <span className="font-bold">{modalData.avg_reach}</span></span>
                                                    <span className="text-gray-800 text-sm font-poppins">Response Rate: <span className="font-bold">{modalData.response_rate}%</span></span>
                                                </div>
                                            </div>
                                            <h1 className="text-lg flex flex-start text-gray-800 font-extrabold font-poppins mt-8 mb-1">Audience Data</h1>
                                            <div className="flex flex-row gap-2 justify-evenly">
                                                <div className="px-2 w-full flex flex-col items-center rounded-2xl h-full text-lg shadow-xl p-4 bg-white overflow-auto">
                                                    <h1 className="flex font-poppins text-mi-blue text-base mb-2">Location</h1>
                                                    {modalData.location_insights.map((locations, index) => {
                                                        return <span key={index} className="text-gray-800 text-sm font-poppins">{locations.location}: {locations.value}%</span>
                                                    })}
                                                </div>
                                                <div className="px-2 w-full flex flex-col items-center rounded-2xl h-full text-lg shadow-xl p-4 bg-white overflow-auto">
                                                    <h1 className="font-poppins text-mi-blue text-base mb-2">Gender</h1>
                                                    <span key={0} className="text-gray-800 text-xs font-poppins">{"Male"}: {modalData["gender_insights"]["male"]}%</span>
                                                    <span key={1} className="text-gray-800 text-xs font-poppins">{"Female"}: {modalData["gender_insights"]["female"]}%</span>
                                                    <span key={2} className="text-gray-800 text-xs font-poppins">{"Unspecified"}: {modalData["gender_insights"]["non_binary"]}%</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* footer */}
                                <div className="flex flex-col justify-center items-center shadow-t-md sticky top-[100vh]">
                                    <div className="flex flex-nowrap justify-center items-center md:justify-end gap-4">
                                        <div className="w-6/12 mt-1 py-5 text-xs">
                                            <TWSelect onChange={e => setCampaignSelect(e.target.value)}>
                                                <option value="">Select A Campaign</option>
                                                {props.campaignList.map((campaign, index) => (
                                                    <option key={index} value={campaign.campaign_id}>{campaign.campaign_title}</option>
                                                ))}
                                            </TWSelect>
                                        </div>

                                        <div className="py-1">
                                            <TWButton xs disabled={!campaignSelect || loading} loading={loading} onClick={() => setLoading(true)}>
                                                Queue Proposal
                                            </TWButton>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )}

                        {/* CREATE LIST IN EXPLORE */}
                        {createModal && <CreateListModal setShowModal={setCreateModal} onNewListCreated={handleNewListCreated} />}
                    </div>

                }
            </Modal>

        </>
    );
}
export default ProfileModal;