import { useEffect, useState, Fragment, forwardRef, useRef } from "react";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import Modal from "../Modal";
import TWButton from "../TWButton";
import TWTextField from "../TWTextField";
import TWTextArea from "../TWTextArea";
import TWDropDown from "../TWDropDown";
import { InfoPing } from "../Pings";
import DatePicker from "react-datepicker";
import ScheduleForm from "./ScheduleTime";
import CloseBlackButton from "../../assets/images/black_close_button.svg";
import CalendarComponentBusiness from "./CalendarBusiness";
import CloseBlueButton from "../../assets/images/blue_close_button.svg";

const CreateCampaignModal = (props) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [promotionType, setPromotionType] = useState("");
    const [additionalDetails, setAdditionalDetails] = useState("");
    const [dateRange, setDateRange] = useState([]);

    const [dailyLimit, setDailyLimit] = useState(4);

    const [unavailableDates, setUnavailableDates] = useState([]);

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 4;

    const goForward = () => setCurrentStep((prev) => Math.min(prev + 1, totalSteps));
    const goBackward = () => setCurrentStep((prev) => Math.max(prev - 1, 1));

    const isUnavailableDate = (date) =>
        unavailableDates.some(unavailableDate =>
            date.getFullYear() === unavailableDate.getFullYear() &&
            date.getMonth() === unavailableDate.getMonth() &&
            date.getDate() === unavailableDate.getDate()
        );
    
    const onDateChange = (date) => {
        console.log(date);
        if (currentStep === 2) {
            const currentTime = new Date(); // Get the current time
            if (Array.isArray(date) && date.length === 2) { // Check if the date is a range
                const updatedDates = date.map((d) => {
                    if (d !== null) {
                        const newDate = new Date(d); // Ensure it's a Date object
                        newDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                        return newDate;
                    }
                    return null;
                });
                setDateRange(updatedDates);
            } else {
                setDateRange(date); // Handle single date or other cases as is
            }
        }
        if (currentStep > 2) {
            const datesToCheck = Array.isArray(date) ? date : [date]; // Ensure datesToCheck is always an array
    
            for (const d of datesToCheck) {
                if (d !== null && typeof d.toLocaleDateString === 'function') { 
                    const dayOfWeek = d.toLocaleDateString('en-US', { weekday: 'short' }); // e.g., "Fri"
                    if (!dayAvailability[dayOfWeek]) {
                        Swal.fire({
                            icon: "warning",
                            title: "Attention!",
                            text: `You've disabled ${dayOfWeek} in the weekly hours but selected ${d.toLocaleDateString('en-US')} to be available. Please click on the dates individually to set a custom schedule.`,
                            confirmButtonColor: "#7BBA83",
                        });

                        handleDayAvailabilityChange({
                            ...dayAvailability,
                            [dayOfWeek]: true,
                        });
                    }
                }
            }
        }
    };

    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }

    const handleDayAvailabilityChange = (updatedAvailability) => {
        setDayAvailability(updatedAvailability);
    };

    const [dayAvailability, setDayAvailability] = useState({
        Sun: true,
        Mon: true,
        Tue: true,
        Wed: true,
        Thu: true,
        Fri: true,
        Sat: true,
    });

    const [timeAvailability, setTimeAvailability] = useState({
        Sun: { start: "09:00", end: "21:00" },
        Mon: { start: "09:00", end: "21:00" },
        Tue: { start: "09:00", end: "21:00" },
        Wed: { start: "09:00", end: "21:00" },
        Thu: { start: "09:00", end: "21:00" },
        Fri: { start: "09:00", end: "21:00" },
        Sat: { start: "09:00", end: "21:00" },
    });

    const handleScheduleUpdate = (updatedSchedule) => {
        const newDayAvailability = {};
        const newTimeAvailability = {};
        Object.entries(updatedSchedule).forEach(([day, { enabled, start, end }]) => {
            newDayAvailability[day] = enabled;
            newTimeAvailability[day] = { start, end };
        });
        setDayAvailability(newDayAvailability);
        setTimeAvailability(newTimeAvailability);
    };

    // Reference to store previous day availability
    const prevDayAvailability = useRef(dayAvailability);

    useEffect(() => {
        // Check if dateRange is incomplete or empty and reset states
        if (!dateRange.length || dateRange.length === 1 || !dateRange[0] || !dateRange[1]) {
            setUnavailableDates([]);
            setDayAvailability({
                Sun: true, Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: true
            });
        }
    }, [dateRange]); // This effect purely handles resetting states

    useEffect(() => {
        if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
            const startDate = new Date(dateRange[0]);
            const endDate = new Date(dateRange[1]);

            let allDatesInRange = [];
            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                allDatesInRange.push(new Date(d.getTime()));
            }

            const changes = {};
            Object.keys(dayAvailability).forEach(day => {
                if (dayAvailability[day] !== prevDayAvailability.current[day]) {
                    changes[day] = dayAvailability[day];
                }
            });

            if (Object.keys(changes).length > 0) {
                updateUnavailableDatesBasedOnChanges(changes, allDatesInRange);
            }

            // Update the ref to the current state for the next render
            prevDayAvailability.current = dayAvailability;
        }
    }, [dayAvailability, dateRange]);

    const updateUnavailableDatesBasedOnChanges = (changes, allDatesInRange) => {
        setUnavailableDates(current => {
            const newUnavailableDates = allDatesInRange.filter(date => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                return changes[dayName] === false;
            });

            const datesToRemove = allDatesInRange.filter(date => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                return changes[dayName] === true;
            });

            const updatedDates = current.filter(date =>
                !datesToRemove.some(d =>
                    d.getDate() === date.getDate() &&
                    d.getMonth() === date.getMonth() &&
                    d.getFullYear() === date.getFullYear()
                )
            );

            return [...new Set([...updatedDates, ...newUnavailableDates])]; // Combine and remove duplicates
        });
    };


    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [newClientName, setNewClientName] = useState("");
    const [clientNumbers, setClientNumbers] = useState([]);
    const [newClientNumber, setNewClientNumber] = useState("");
    const [isAddingNewClientName, setAddingNewClientName] = useState(false);
    const [isAddingNewClientNumber, setAddingNewClientNumber] = useState(false);

    const handleClientChange = (selectedValue) => {
        if (selectedValue === "-99") {
            setAddingNewClientName(true);
            setAddingNewClientNumber(true);
            setNewClientName("");
            setNewClientNumber("");
            setClientNumbers([]);
            setSelectedClient(null);
        } else if (selectedValue === "Select Client") {
            setAddingNewClientName(false);
            setAddingNewClientNumber(false);
            setSelectedClient(null);
            setNewClientName("");
            setNewClientNumber("");
            setClientNumbers([]);
        } else {
            const client = clientOptions.find(c => c.value === selectedValue);
            if (client) {
                setSelectedClient({
                    ...client,
                    numbers: []
                });
                setClientNumbers(client.numbers || []);
                setNewClientName(client.label);
                setAddingNewClientName(false);
            }
        }
    };



    const handleClientNumberChange = (selectedOption) => {
        if (selectedOption === "-99") {
            setAddingNewClientNumber(true);
            setNewClientNumber("");
            setSelectedClient(prev => ({
                ...prev,
                numbers: []
            }));
        } else if (selectedOption === "Select Number") {
            setAddingNewClientNumber(false);
            setNewClientNumber("");
            setSelectedClient(prev => ({
                ...prev,
                numbers: clientNumbers.length > 0 ? [clientNumbers[0]] : []
            }));
        } else {
            const selectedNumber = clientNumbers.find(num => num.id.toString() === selectedOption);
            if (selectedNumber) {
                setNewClientNumber(selectedNumber.number);
                setAddingNewClientNumber(false);
                setSelectedClient(prev => ({
                    ...prev,
                    numbers: [selectedNumber]
                }));
            }
        }
    };



    const cancelNewClientAndNumber = () => {
        setAddingNewClientName(false);
        setAddingNewClientNumber(false);
        setSelectedClient(clientOptions[0] || null);
        setNewClientName("");
        setNewClientNumber("");
    };

    const [product, setProduct] = useState([
        {
            productName: null,
            productDescription: null,
        }
    ]);

    const [loading, setLoading] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    const [serviceList, setServiceList] = useState([]);

    const handleServiceChange = (e, index) => {
        const list = [...serviceList];
        list[index] = e;
        setServiceList(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };

    const handleServiceAdd = () => {
        setServiceList([...serviceList, []]);
    };

    const promotions = [
        {
            value: "P",
            label: "Product",
        },
        {
            value: "S",
            label: "Service",
        },
    ];

    const formComplete = () => {
        const basicChecks = title && description && product && promotionType && checkProdData() && checkProdLength();
        if (props.accountType === "Agency") {
            return basicChecks && checkClientData();
        } else {
            return basicChecks;
        }
    }


    const productName = (index, prod) => {
        setProduct((prev) => {
            const newProduct = prev.map(a => { return { ...a } });
            newProduct[index].productName = prod;
            return newProduct;
        });
    };
    const productDesc = (index, prod) => {
        setProduct((prev) => {
            const newProduct = prev.map(a => { return { ...a } });
            newProduct[index].productDescription = prod;
            return newProduct;
        });
    };

    const addProduct = () => {
        setProduct((prev) => {
            const newProduct = prev.map(a => { return { ...a } });
            newProduct.push({
                productName: null,
                productDescription: null,
            });
            return newProduct;
        });
    };

    const deleteProduct = (index) => {
        const list = [...product];
        list.splice(index, 1);
        setProduct(list);
    };

    const checkProdLength = () => {
        if (product.length === 0 && promotionType !== "L") {
            return false
        }
        return true
    }

    const checkProdData = () => {
        for (const data of product) {
            if (!(data.productName && data.productDescription) && promotionType !== "L") {
                return false;
            }
        }
        return true;
    };
    const checkClientData = () => {
        return (
            selectedClient === "" ||
            (selectedClient.value === "-99" && newClientName !== null && newClientName !== "" && newClientNumber !== null && newClientNumber !== "") ||
            (selectedClient.value !== "-99" && selectedClient.label !== "Select Client" && selectedClient !== null)
        );
    }

    const getHeaderText = (step) => {
        switch (step) {
            case 1:
                return "Create a Campaign: Basic Details";
            case 2:
                return "Create a Campaign: Scheduling Settings";
            case 3:
                return "Create a Campaign: Visiting Hours";
            case 4:
                return "Create a Campaign: Visit Constraints";
            default:
                return "Create a Campaign";
        }
    };

    const getClientList = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/get_client_list`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        const clients = result.data.map(client => ({
                            value: client.id.toString(),
                            label: client.name,
                            numbers: client.numbers || ["No numbers available"]
                        }));
                        setClientOptions([...clients]);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    // function to create and launch a campaign
    const createAndLaunchCampaign = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/create_campaign`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                title: title,
                description: description,
                promotionType: promotionType,
                product: product,
                additionalDetails: additionalDetails,
                status: "A",
                usage: serviceList,
                startDate: dateRange[0],
                endDate: dateRange[1],
                busHrList: timeAvailability,
                dayList: dayAvailability,
                dailyLimit: dailyLimit,
                offDateList: unavailableDates,
                clientId: selectedClient?.value,
                clientNumberId: selectedClient?.numbers[0]?.id,
                newClientName: newClientName,
                newClientNumber: newClientNumber,
            }),
        };

        try {
            const response = await fetch(url, requestMetaData);
            const result = await response.json();
            if (!result || !result.success) {
                if (result.data && result.data.failure_reason) {
                    switch (result.data.failure_reason) {
                        case 1:
                            Swal.fire({
                                icon: "error",
                                title: "Invalid Start Date",
                                text: "The start date needs to be at least today or any day onwards. Please adjust the start date.",
                                confirmButtonColor: "#7BBA83",
                            });
                            break;
                        case 2:
                            Swal.fire({
                                icon: "error",
                                title: "Invalid End Date",
                                text: "The end date must be at least 10 days from the start date. Please adjust the end date.",
                                confirmButtonColor: "#7BBA83",
                            });
                            break;
                        default:
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                            break;
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                }
                setLoading(false);
            } else {
                setLoading(false);
                props.setShowModal(false);
                Swal.fire({
                    icon: "success",
                    title: "Done!",
                    text: "Successfully created and launched campaign!",
                    confirmButtonColor: "#7BBA83",
                }).then(() => window.location.reload());
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    };


    // get client list on page load
    useEffect(() => {
        if (props.accountType === "Agency")
            getClientList();
    }, []);

    useEffect(() => {
        if (loading && formComplete()) {
            createAndLaunchCampaign();
        }
    }, [loading]);

    const removeUnavailableDate = (dateToRemove) => {
        setUnavailableDates(currentDates =>
            currentDates.filter(date =>
                date.getTime() !== dateToRemove.getTime()
            )
        );
    };

    function StepTwo() {
        const DatePickerCustomInput = forwardRef(({ value, onClick, inputName }, ref) => (
            <div style={{ position: 'relative' }}>
                <button
                    className="w-40 rounded-full py-1 shadow-lg bg-gray-200 outline-gray-400 text-gray-700"
                    onClick={(e) => {
                        onClick(e); // Open the DatePicker
                    }}
                    ref={ref}
                >
                    {value || "Select Date"}
                </button>
            </div>
        ));

        const handleDateChange = (date, index) => {
            if (currentStep === 2) { // Ensure changes are only applied if in step 2
                const currentTime = new Date(); // Get the current time
                if (date === null) {
                    setDateRange([null, null]);
                } else {
                    const newDates = [...dateRange];
                    const updatedDate = new Date(date); // Convert to Date object if not already
                    updatedDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
        
                    newDates[index] = updatedDate;
        
                    // Ensure the date range remains valid
                    if (index === 0 && newDates[1] && updatedDate > newDates[1]) {
                        const newEndDate = new Date(newDates[1]);
                        newEndDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                        newDates[1] = newEndDate;
                    }
                    if (index === 1 && newDates[0] && updatedDate < newDates[0]) {
                        const newStartDate = new Date(newDates[0]);
                        newStartDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                        newDates[0] = newStartDate;
                    }
                    setDateRange(newDates);
                }
            }
        };
        
        return (
            <div className="w-min">
                <div className="text-neutral-600 text-base font-semibold font-poppins pb-8">Scheduling Setting</div>
                <div className="text-neutral-600 text-sm font-semibold font-poppins pb-2">Date Range</div>
                <div className="text-gray-600 py-4">Start Date:</div>
                <DatePicker
                    minDate={new Date()}
                    maxDate={dateRange[1]} // End date or no restriction
                    selected={dateRange[0]}
                    onChange={date => handleDateChange(date, 0)}
                    isClearable
                    disabledKeyboardNavigation
                    customInput={<DatePickerCustomInput inputName="start" />}
                />
                <div className="text-gray-600 py-4">End Date:</div>
                <DatePicker
                    minDate={dateRange[0] || new Date()} // Start date or today's date
                    selected={dateRange[1]}
                    onChange={date => handleDateChange(date, 1)}
                    isClearable
                    disabledKeyboardNavigation
                    customInput={<DatePickerCustomInput inputName="end" />}
                />
            </div>
        );
    }

    function StepThree() {
        // Form fields for step 2
        return (

            <div className="w-min">
                <div className="text-neutral-600 text-base font-semibold font-poppins pb-4">Visting Hours</div>
                <div className="text-neutral-600 text-sm font-semibold font-poppins pb-6 w-[90%]">Set the times that influencers will be able to schedule the pickups.</div>

                <div className="text-gray-600 pb-2">Weekly Hours</div>
                <div className="bg-white rounded-xl shadow-custom w-min">
                    <ScheduleForm
                        updateDayAvailability={handleDayAvailabilityChange}
                        dayAvailability={dayAvailability} // Assuming you're also passing down the current state
                        updateSchedule={handleScheduleUpdate}
                        timeAvailability={timeAvailability}
                    />
                </div>
            </div>
        );
    }

    function StepFour() {
        return (
            <div>
                <h2 className="text-base font-semibold text-neutral-600 pb-8 font-poppins">Visit Constraints</h2>

                <div className="mb-4">
                    <label className="text-sm font-semibold text-neutral-600 font-poppins block">Daily Limit</label>
                    <div className="flex items-center">
                        <input
                            type="number"
                            className="w-min rounded-xl p-1 shadow-lg bg-gray-200 outline-none text-gray-700 mr-2"
                            value={dailyLimit}
                            onChange={(e) => setDailyLimit(e.target.value)}
                        />
                        <span className="text-gray-600">visits max per day</span>
                    </div>
                </div>

                <div>
                    <label className="text-sm font-semibold text-neutral-600 font-poppins block">Unavailable Dates</label>
                    <div className="text-gray-600 py-2">Click on a date to remove it from the list of unavailable dates.</div>
                    <div style={{ maxHeight: '280px', overflowY: 'auto' }}> {/* Scrollable container */}
                        <ul className="list-none pl-0">
                            {unavailableDates.sort((a, b) => a - b).map((date, index) => (
                                <li key={index} className="mb-2 flex items-center">
                                    <span className="w-24">{date.toLocaleDateString('en-US')}</span> {/* Set width */}
                                    <button
                                        onClick={() => removeUnavailableDate(date)}
                                        className="flex-shrink-0"
                                    >
                                        <img src={CloseBlueButton} alt="Remove" className="w-4 h-4" />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                {/* header */}
                <div className="w-[95%] ml-4 mr-4">
                    <div className="flex flex-row mt-6 mb-4 items-center w-full border-b border-solid border-slate-200">
                        <h2 className="flex-auto mb-4">
                            {getHeaderText(currentStep)}
                        </h2>
                        <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white fixed top-5 right-2">
                            <img src={CloseBlackButton} onClick={() => props.setShowModal(false)} alt="Close" className="w-6 h-6" />
                        </button>
                    </div>

                    {/* {renderStep()} */}
                    {currentStep === 1 ?
                        <>
                            <div className="flex flex-col gap-2 md:grid md:grid-cols-2 md:w-[50rem]">
                                {props.accountType === "Agency" && (
                                    <>
                                        <div className="mx-2">
                                            <h4>Select Client:</h4>
                                            {isAddingNewClientName ? (
                                                <div className="relative">
                                                    <TWTextField
                                                        whitebox
                                                        value={newClientName}
                                                        onChange={e => {
                                                            setNewClientName(e);
                                                            setSelectedClient(prev => ({
                                                                ...prev,
                                                                label: e, // Updating the label as the new client name is typed
                                                                value: "-99" // Keeping value as -99 to indicate it's a new entry
                                                            }));
                                                        }}
                                                        placeholder="Enter new client name"
                                                    />
                                                </div>
                                            ) : (
                                                <TWDropDown white data={[...clientOptions, { value: "-99", label: "Add New Client" }]} onChange={handleClientChange} value={selectedClient?.value || ""}>
                                                    Select Client
                                                </TWDropDown>
                                            )}
                                        </div>
                                        <div className="mx-2">
                                            <h4>Select Client Number:</h4>
                                            {isAddingNewClientNumber ? (
                                                <div className="relative">
                                                    <TWTextField
                                                        whitebox
                                                        value={newClientNumber}
                                                        onChange={e => {
                                                            setNewClientNumber(e);
                                                            setSelectedClient(prev => ({
                                                                ...prev,
                                                                numbers: [{
                                                                    id: "-99",
                                                                    number: e // Updating the number in the numbers array
                                                                }]
                                                            }));
                                                        }}
                                                        placeholder="Enter new client number" />
                                                    <button className="absolute top-0 right-0" onClick={cancelNewClientAndNumber} style={{ cursor: 'pointer' }}>
                                                        <img src={CloseBlackButton} alt="Close" className="w-6 h-6" />
                                                    </button>
                                                </div>
                                            ) : (
                                                <TWDropDown white
                                                    data={[...clientNumbers.map(num => ({ value: num.id.toString(), label: num.number })), { value: "-99", label: "Add New Number" }]} onChange={handleClientNumberChange}
                                                    value={selectedClient?.numbers[0]?.id || ""}
                                                    disabled={!selectedClient && !isAddingNewClientName}>Select Number</TWDropDown>
                                            )}
                                        </div>
                                    </>
                                )}


                                <div className="mb-4 md:px-2">
                                    <h4 className="w-full font-semibold text-gray-600">Campaign Title</h4>
                                    <div className="flex">
                                        <TWTextField whitebox placeholder="e.g. New 5 Topping Pizza!" value={title} onChange={setTitle} />

                                    </div>
                                </div>

                                <div className="mb-4 md:px-2">
                                    <h4 className="w-full font-semibold text-gray-600">Promotion Type</h4>
                                    <div className="flex">
                                        <TWDropDown white height="12" data={promotions} value={promotionType} onChange={setPromotionType}>Select</TWDropDown>
                                    </div>
                                </div>

                                <div className="mb-4 md:px-2">
                                    <h4 className="w-full font-semibold text-gray-600">Campaign Description</h4>
                                    <div className="flex">
                                        <TWTextArea placeholder="e.g. Promoting our new menu item." value={description} onChange={setDescription} />
                                    </div>
                                </div>


                                <div className="mb-2 md:px-2">
                                    <h4 className="w-full font-semibold text-gray-600">Additional Details</h4>
                                    <div className="flex">
                                        <TWTextArea placeholder="e.g. Based in Atlanta." value={additionalDetails} onChange={setAdditionalDetails} />
                                    </div>

                                </div>
                            </div>
                            <div className="flex flex-row w-full items-center px-5 pb-5 justify-between">
                                <div>
                                    <h4 className="text-xl DosisBold text-mi-black">Products</h4>
                                </div>
                                <div>
                                    {product.length < 4 && (
                                        <TWButton sm type="button"
                                            onClick={addProduct}
                                            className="add-btn">+ Add Product</TWButton>
                                    )}
                                </div>
                            </div>

                            <div className="w-full mb-4">
                                {product.map((prod, index) => (
                                    <div key={index} className="flex flex-col p-4 gap-6 md:grid md:grid-cols-6 md:w-[50rem] items-center mx-auto">
                                        <div className="md:col-span-1 w-full">
                                            <h4 className="text-sm font-semibold text-gray-600 text-center md:text-left">Product {index + 1}</h4>
                                        </div>
                                        <div className="md:col-span-2 w-full">
                                            <TWTextField
                                                whitebox
                                                value={prod.productName}
                                                onChange={e => productName(index, e)}
                                                placeholder="Name"
                                            />
                                        </div>
                                        <div className="md:col-span-2 w-full">
                                            <TWTextField
                                                whitebox
                                                value={prod.productDescription}
                                                onChange={e => productDesc(index, e)}
                                                placeholder="Description"
                                            />
                                        </div>
                                        <div className="md:col-span-1 w-full flex justify-end">
                                            <TWButton
                                                delete
                                                sm
                                                onClick={() => product.length > 1 && deleteProduct(index)}
                                                disabled={product.length <= 1}
                                                className={`${product.length <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            >
                                                Delete
                                            </TWButton>
                                        </div>
                                    </div>
                                ))}

                            </div>

                            <div className="flex gap-2 items-center w-full px-5 pb-5 justify-between">
                                <div>
                                    <h4 className="w-full font-semibold text-gray-600">
                                        Intended Usage
                                        <InfoPing placement="right-start" className="ml-2 cursor-pointer">
                                            <Fragment>
                                                <div className="text-sm text-gray-500">Please add <em><u>specific URL's</u></em> of anywhere you intend to use the content created by the influencer in order to <b>reserve rights</b> to use the content there.</div>
                                            </Fragment>
                                        </InfoPing>
                                    </h4>
                                </div>
                                <div>
                                    {serviceList.length < 4 && (
                                        <TWButton sm type="button"
                                            onClick={handleServiceAdd}
                                            className="add-btn">Add Usage</TWButton>
                                    )}
                                </div>
                            </div>
                            {serviceList.map((singleService, index) => (
                                <div className="flex gap-2 items-center w-full pb-3 px-5" key={index}>
                                    {(
                                        <div className="flex flex-col p-2 gap-3 md:grid md:grid-cols-6 md:w-[50rem] w-full">
                                            <div className="col-span-5 md:items-center">
                                                <TWTextField
                                                    whitebox
                                                    placeholder="e.g. https://www.facebook.com/your_page"
                                                    value={singleService.service}
                                                    onChange={e => { handleServiceChange(e, index); }}
                                                    required>
                                                </TWTextField>
                                            </div>
                                            <div className="col-span-1 md:px-2">
                                                <div className="md:w-full w-1/3 mx-auto md:mx-none">
                                                    <TWButton sm delete onClick={() => handleServiceRemove(index)}>Remove</TWButton>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </> : <></>}
                    {currentStep >= 2 && (
                        <>
                            <div className="flex flex-col md:flex-row gap-12 mb-8">

                                <CalendarComponentBusiness
                                    onDateChange={onDateChange}
                                    isSameDay={isSameDay}
                                    isUnavailableDate={isUnavailableDate}
                                    setUnavailableDates={setUnavailableDates}
                                    dateRange={dateRange}
                                    selectSingleDate={false}
                                    currentStep={currentStep}
                                />
                                {currentStep === 2 && <StepTwo />}
                                {currentStep === 3 && <StepThree />}
                                {currentStep === 4 && <StepFour />}
                            </div>
                        </>
                    )}

                    {/* footer */}
                    <div className="flex flex-row gap-2 mb-4 mr-4 items-center md:justify-end w-full pt-3 border-t border-solid border-slate-200">
                        <div className="flex-auto md:grow-0">
                            {currentStep !== 1 && (
                                <TWButton md onClick={goBackward} hidden={currentStep === 1} disabled={currentStep === 1}>
                                    Previous
                                </TWButton>
                            )}
                        </div>
                        <div className="flex-auto md:grow-0">
                            {currentStep === totalSteps ? (
                                <TWButton md loading={loading} disabled={!formComplete() || loading} onClick={() => { setLoading(true); }}>
                                    Submit
                                </TWButton>
                            ) : (
                                <TWButton md onClick={() => {
                                    if (currentStep !== 2 || (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1])) {
                                        goForward();
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Date Range Required",
                                            text: "Please select a valid date range before proceeding to the next step.",
                                            confirmButtonColor: "#7BBA83",
                                        });
                                    }
                                }}
                                    hidden={currentStep === totalSteps} disabled={currentStep === totalSteps}>
                                    Next Step
                                </TWButton>
                            )}
                        </div>
                    </div>

                </div>



            </Modal >
        </>
    );
}
export default CreateCampaignModal;
