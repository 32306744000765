import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import TWButton from "../TWButton";
import TWDropDown from "../TWDropDown";
import { InfoPing } from "../Pings";

const BusinessSignup = (props) => {
    const [companyName, setCompanyName] = useState(null);
    const [category, setCategory] = useState(null);
    const [otherCategory, setOtherCategory] = useState("");
    const [companyURL, setCompanyURL] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [companySize, setCompanySize] = useState(null);


    const nextStep = () => {
        const data = {
            companyName: companyName,
            category: category,
            otherCategory: otherCategory,
            companyURL: companyURL,
            phoneNumber: phoneNumber,
            companySize: companySize,
        };

        Swal.fire({
            icon: "success",
            title: "Done!",
            text: "Account created!",
            confirmButtonColor: "#7BBA83",
        }).then(props.nextStep(data));
    };


    const isValidPhoneNumber = (number) => {
        const regex = /^(\+\d{1,2}\s)?\d{1,4}\s?\d{1,4}\s?\d{1,4}$/;
        return regex.test(number);
    };

    useEffect(() => {
        if (category !== 50) {
            setOtherCategory("");
        }
    }, [category]);

    return (
        <>
            <h3 className="font-bold text-2xl text-gray-600 mb-10">Welcome, {props.name}!</h3>

            <h4 className="w-full px-10 font-semibold text-gray-500">Company Name</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" placeholder="e.g. Bob's Diner" onChange={e => setCompanyName(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Category</h4>
            <div className="w-full flex px-10 mb-10">
                <TWDropDown
                    onChange={setCategory}
                    data={
                        [
                            { value: 1, label: "Advertising/Marketing" },
                            { value: 2, label: "Aesthetician" },
                            { value: 3, label: "Arts & Entertainment" },
                            { value: 4, label: "Wedding Planning Service" },
                            { value: 5, label: "Automotive" },
                            { value: 6, label: "Bar" },
                            { value: 7, label: "Beauty Salon" },
                            { value: 8, label: "Brick and Mortar" },
                            { value: 9, label: "Clothing" },
                            { value: 10, label: "Community Organization" },
                            { value: 11, label: "Cosmetic & Personal Care" },
                            { value: 12, label: "Doctor" },
                            { value: 13, label: "Dance" },
                            { value: 14, label: "Design" },
                            { value: 15, label: "Electric" },
                            { value: 16, label: "Entertainment" },
                            { value: 17, label: "Event" },
                            { value: 18, label: "Fashion Designer" },
                            { value: 19, label: "Food & Beverage" },
                            { value: 20, label: "Footwear Store" },
                            { value: 21, label: "Grocery Store" },
                            { value: 22, label: "Hardware Store" },
                            { value: 23, label: "Home Decor" },
                            { value: 24, label: "Home Improvement" },
                            { value: 25, label: "Hotel & Lodging" },
                            { value: 26, label: "Interior Design Studio" },
                            { value: 27, label: "Jewelry/Watches" },
                            { value: 28, label: "Karaoke" },
                            { value: 29, label: "Kennel" },
                            { value: 30, label: "Kitchen/Cooking" },
                            { value: 31, label: "Landmark & Historical Place" },
                            { value: 32, label: "Lawyer & Law Firm" },
                            { value: 33, label: "Local Service" },
                            { value: 34, label: "Marketing Agency" },
                            { value: 35, label: "Music Lessons & Instruction School" },
                            { value: 36, label: "Nonprofit Organization" },
                            { value: 37, label: "Non-Governmental Organization (NGO)" },
                            { value: 38, label: "Optician" },
                            { value: 39, label: "Optometrist" },
                            { value: 40, label: "Outdoor & Sporting Goods Company" },
                            { value: 41, label: "Personal Blog" },
                            { value: 42, label: "Photographer" },
                            { value: 43, label: "Real Estate" },
                            { value: 44, label: "Record Label" },
                            { value: 45, label: "Restaurant" },
                            { value: 46, label: "Retail" },
                            { value: 47, label: "Sports & Recreation" },
                            { value: 48, label: "Video Creator" },
                            { value: 49, label: "Web Designer" },
                            { value: 50, label: "Other" },
                        ]
                    } />
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Other:</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" disabled={category !== 50} placeholder="e.g. Dog Walker" value={otherCategory} onChange={e => setOtherCategory(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 focus:text-gray-700 disabled:cursor-not-allowed"></input>
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Phone Number</h4>
            <div className="w-full flex px-10 mb-10 items-center">
                <input
                    type="tel"
                    placeholder="e.g. +1 123 456 7890"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                    className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"
                />
                {phoneNumber && !isValidPhoneNumber(phoneNumber) && (
                    <InfoPing className="ml-2">Please enter a valid phone number without dashes. (e.g. +1 123 456 7890 or +1 1234567890)</InfoPing>
                )}
            </div>
            
            <h4 className="w-full px-10 font-semibold text-gray-500">Company Size</h4>
            <div className="w-full flex px-10 mb-10">
                <select
                    onChange={(e) => setCompanySize(e.target.value)}
                    className="rounded-xl bg-gray-200 outline-gray-400 text-gray-700 px-2 py-0.5"
                >
                    <option value="">Select Number Of Employees</option>
                    <option value={1}>1-10</option>
                    <option value={2}>11-50</option>
                    <option value={3}>51-100</option>
                    <option value={4}>101-500</option>
                    <option value={5}>500+</option>
                </select>
            </div>


            <h4 className="w-full px-10 font-semibold text-gray-500">Website (optional):</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" placeholder="e.g. https://example.com" onChange={e => setCompanyURL(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
            </div>

            <div className="flex-auto w-full px-10 flex flex-row flex-wrap-reverse justify-center md:justify-end items-center">
                <div className="flex-auto flex flex-row justify-start">
                    <div className="w-full md:w-[40%]">
                        <TWButton onClick={props.prevStep}>Back</TWButton>
                    </div>
                </div>
                <div className="w-full md:w-[30%]">
                    <TWButton
                        disabled={
                            !companyName ||
                            !category ||
                            (category === 50 && !otherCategory) ||
                            !isValidPhoneNumber(phoneNumber)
                            || !companySize
                        }
                        onClick={nextStep}>
                        Continue
                    </TWButton>
                </div>
            </div>
        </>
    );
}
export default BusinessSignup;