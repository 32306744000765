import TWButton from "../TWButton";
import influencerIcon from "../../assets/images/influencerIcon.png"
import businessIcon from "../../assets/images/businessIcon.png"
import logoGif from "../../assets/images/logoGif.gif";
import LoginIcon1 from "../../assets/images/LoginIcon1.png";
import LoginIcon2 from "../../assets/images/LoginIcon2.png";

const ChooseType = ({ setUserType }) => {
    const setType = (type) => {
        setUserType(type);
    }

    return (
        <>
            <div className="grid grid-cols-1 justify-between items-center h-screen">
                <img src={LoginIcon1} alt="Login Image1" className="w-1/5 justify-self-start"/>
                <img src={LoginIcon2} alt="Login Image2" className="w-1/5 justify-self-end"/>
                <div className="flex flex-col p-20 justify-around justify-self-center bg-white md:bg-transparent md:rounded-xl m-2 h-screen w-screen md:mx-auto md:w-2/3 md:h-2/3 absolute">
                    <img src={logoGif} alt="My Influency" className="object-scale-down w-64 mx-auto mb-8" />
                    <div className="flex flex-col md:flex-row justify-around gap-20 md:gap-0">
                        <div onClick={() => setType("Influencer")} className="flex flex-col items-center h-64 md:w-64 md:p-4 bg-white md:shadow-2xl md:rounded-xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ">
                                <img src={influencerIcon} alt="Influencer Icon" className="object-fit w-5/6 m-auto mb-4 md:h-40 md:my-auto"/>
                                <TWButton onClick={() => setType("Influencer")}>I am an Influencer</TWButton>
                        </div>
                        <div onClick={() => setType("Business")} className="flex flex-col items-center h-64 bg-white md:w-64 md:p-4 md:shadow-2xl md:rounded-xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ">
                                <img src={businessIcon} alt="Business Icon" className="object-fit md:h-40 md:pt-4 md:my-auto"/>
                                <TWButton onClick={() => setType("Business")}>I am a Business</TWButton>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </>
    );
}
export default ChooseType;