import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import CropPicture from "../CropPicture";
import TWButton from "../TWButton";
import Modal from "../Modal";
import { useMediaQuery } from "react-responsive";

const CropPicModal = (props) => {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });

    const csrf_token = Cookies.get("csrftoken");

    useEffect(() => {
        if (loading) {
            const updatePFP = async () => {
                const token = await auth.currentUser.getIdToken();
                const url = `${process.env.REACT_APP_DJANGO_API}/update_profile_picture`;
                const requestMetaData = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify({ "pfp": image }),
                };
                await fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            props.setSrc(result.data);
                            setLoading(false);
                            props.setCropPicModal(false);
                            Swal.fire({
                                icon: "success",
                                title: "Done!",
                                text: "Successfully updated profile picture!",
                                confirmButtonColor: "#7BBA83",
                            }).then(() => window.location.reload());
                        }
                    });
            }
            updatePFP();
        }
    }, [loading]);

    return (
        <>
            <Modal {...(isSmallScreen && { minimum: true })} setShowModal={props.setCropPicModal}>
                <div className="p-7">
                    {/* header */}
                    <div className="flex flex-row gap-2 items-center w-full pb-3 border-b border-solid border-slate-200">
                        <div className="flex-auto font-semibold text-lg text-gray-500">
                            Set Profile Image
                        </div>
                    </div>

                    {/* body */}
                    <div className="flex flex-col p-4 gap-2 md:grid md:grid-cols-2 md:w-[50rem]">
                        <div className="mb-4 md:col-span-2 md:px-2">
                            <CropPicture setImage={setImage} />
                        </div>
                    </div>

                    {/* footer */}
                    <div className="flex flex-row gap-2 items-center md:justify-end w-full pt-3 border-t border-solid border-slate-200">
                        <div className="flex-auto md:grow-0">
                            <TWButton md loading={loading} onClick={() => setLoading(true)}>
                                Update Profile Picture
                            </TWButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default CropPicModal;