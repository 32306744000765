import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import TWButton from "../TWButton";
import TWDropDown from "../TWDropDown";
import { InfoPing } from "../Pings";
import GAZipCodes from "../../GAZipCodes.json";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Ping } from "../Pings";

const InfluencerSignup = (props) => {
    const [dob, setDob] = useState(null);
    const [gender, setGender] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [category, setCategory] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [otherCategory, setOtherCategory] = useState("");
    const [tag, setTag] = useState([]);

    const nextStep = () => {
        const formattedTags = tag.filter(t => t !== '');
        const invalidTags = formattedTags.filter(t => !t.startsWith('#'));
        if (invalidTags.length > 0) {
            Swal.fire({
                icon: "error",
                title: "Invalid Tags",
                text: "All tags must start with a #. Please correct the tag(s).",
                confirmButtonColor: "#7BBA83",
            });
            return;
        }
        const data = {
            dob: dob,
            zipCode: zipCode,
            gender: gender,
            category: category,
            tags: formattedTags,
            otherCategory: otherCategory,
            phoneNumber: phoneNumber,
        };

        Swal.fire({
            icon: "success",
            title: "Done!",
            text: "Account created!",
            confirmButtonColor: "#7BBA83",
        }).then(props.nextStep(data));
    }

    const isValidZipCode = (code) => {
        const regex = /^\d{5}(-\d{4})?$/;
        if (!regex.test(code)) {
            return false;
        }

        const numCode = Number(code);
        return GAZipCodes.GAZipCodes.includes(numCode);
    };

    useEffect(() => {
        if (category !== 50) {
            setOtherCategory("");
        }
    }, [category]);

    const addTag = () => {
        setTag((prev) => [...prev, ""]);
    };

    const tagName = (index, tagName) => {
        setTag((prevTags) => {
            const newTags = [...prevTags];
            newTags[index] = tagName;
            return newTags;
        });
    };

    const deleteTag = (index) => {
        const list = [...tag];
        list.splice(index, 1);
        setTag(list);
    };

    return (
        <>
            <h3 className="font-bold text-2xl text-gray-600 mb-10">Welcome, {props.name}!</h3>

            <h4 className="w-full px-10 font-semibold text-gray-500">Date Of Birth</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="date" onChange={e => setDob(e.target.value)} className="rounded-xl bg-gray-200 outline-gray-400 text-gray-700 px-2 py-0.5" />
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Gender</h4>
            <div className="w-full flex px-10 mb-10">
                <TWDropDown
                    onChange={setGender}
                    data={
                        [
                            { value: "M", label: "Male" },
                            { value: "F", label: "Female" },
                            { value: "N", label: "Non-Binary" }
                        ]
                    } />
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Zip Code</h4>
            <div className="w-full flex px-10 mb-10 items-center">
                <input
                    type="text"
                    placeholder="e.g. 3000"
                    onChange={(e) => setZipCode(e.target.value)}
                    required
                    className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"
                />
                {zipCode && !isValidZipCode(zipCode) && (
                    <InfoPing className="ml-2">Invalid zipcode format</InfoPing>
                )}
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Category</h4>
            <div className="w-full flex px-10 mb-10">
                <TWDropDown
                    onChange={setCategory}
                    data={
                        [
                            { value: 1, label: "Artist" },
                            { value: 2, label: "Celebrity" },
                            { value: 3, label: "Gamer" },
                            { value: 4, label: "Skincare" },
                            { value: 5, label: "Blogger" },
                            { value: 6, label: "Makeup/Beauty" },
                            { value: 7, label: "Fashion" },
                            { value: 8, label: "Travel" },
                            { value: 9, label: "Lifestyle" },
                            { value: 10, label: "Health" },
                            { value: 11, label: "Fitness" },
                            { value: 12, label: "Luxury" },
                            { value: 13, label: "Pets" },
                            { value: 14, label: "Food" },
                            { value: 15, label: "Cultural" },
                            { value: 16, label: "Parenting" },
                            { value: 17, label: "Sustainability" },
                            { value: 18, label: "Other" },
                        ]
                    }>Select Category</TWDropDown>
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Tags</h4>
            <div className="w-full h-9 flex px-10 mb-10 gap-2">
                {tag.map((t, index) => (
                    <div key={index} className="flex flex-col gap-2">
                        <div className="relative flex w-full">
                            <input type="text" placeholder="#MyInfluency" value={t} onChange={e => tagName(index, e.target.value)} required className="flex-auto w-full rounded-xl p-1 pr-8 bg-gray-200 outline-gray-400 focus:text-gray-700 disabled:cursor-not-allowed"></input>
                            <button disabled={tag.length <= 1}
                                className={`h-min w-fit scale-125 absolute right-1 top-[0.135rem] ${tag.length <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={() => tag.length > 1 && deleteTag(index)}>
                                <Ping className="text-xs text-gray-500 hover:text-neutral-400" >x</Ping>
                            </button>
                        </div>

                    </div>
                ))}
                <div className="shrink-0">
                    {tag.length < 5 && (
                        <TWButton sm type="button"
                            onClick={addTag}
                            className="add-btn">+ Add Tag</TWButton>
                    )}

                </div>
            </div>


            <h4 className="w-full px-10 font-semibold text-gray-500">Other</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" disabled={category !== 18} placeholder="e.g. Bird Watching" value={otherCategory} onChange={e => setOtherCategory(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 focus:text-gray-700 disabled:cursor-not-allowed"></input>
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Phone Number</h4>
            <div className="w-full flex px-10 mb-10 items-center">
                <PhoneInput
                    placeholder="e.g. +1 123 456 7890"
                    defaultCountry="US"
                    inputClassName="custom-phone-input"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400"
                />
                {phoneNumber && !isValidPhoneNumber(phoneNumber) && (
                    <InfoPing className="ml-2">
                        Please enter a valid phone number. (e.g. 123 456 7890 or 1234567890)
                    </InfoPing>
                )}
            </div>
            <div className="flex-auto w-full px-10 flex flex-row flex-wrap-reverse justify-center md:justify-end items-center">
                <div className="flex-auto flex flex-row justify-start">
                    <div className="w-full md:w-[40%]">
                        <TWButton onClick={props.prevStep}>Back</TWButton>
                    </div>
                </div>
                <div className="w-full md:w-[30%]">
                    <TWButton
                        disabled={
                            !dob ||
                            !gender ||
                            !zipCode ||
                            !isValidZipCode(zipCode) ||
                            !category ||
                            !tag ||
                            (category === 18 && !otherCategory) ||
                            !phoneNumber ||
                            !isValidPhoneNumber(phoneNumber)
                        }
                        onClick={nextStep}
                    >
                        Continue
                    </TWButton>
                </div>
            </div>
        </>
    );
}
export default InfluencerSignup;