import { useState } from "react";
import ProfilePic from "../ProfilePic";
import TWButton from "../TWButton";
import CropPicModal from "./CropPicModal";


const ProfilePicSettings = ({ data }) => {
    const [cropPicModal, setCropPicModal] = useState(false);
    const [src, setSrc] = useState(data.profile_picture)

    return (
        <>
            <div className="flex flex-col w-full h-full px-10 md:px-40 py-2">
                <h3 className="w-full font-bold text-2xl text-gray-600 my-5 md:mb-0">Profile Image</h3>
                <div className="rounded-full h-36 w-36 mx-auto">
                    <ProfilePic border image={src} />
                </div>
                <div className = "self-center py-3">
                    <TWButton sm onClick={() => setCropPicModal(true)}>
                        Change Profile Image
                    </TWButton>
                </div>
                {cropPicModal && <CropPicModal setSrc={setSrc} setCropPicModal={setCropPicModal} />}
            </div>
        </>
    );
}
export default ProfilePicSettings;