import { auth, } from '../../firebase';
import { Helmet } from "react-helmet";
import { useState } from "react";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import GoogleButton from 'react-google-button'
import logoGif from "../../assets/images/logoGif.gif";
import LoginIcon1 from "../../assets/images/LoginIcon1.png";
import LoginIcon2 from "../../assets/images/LoginIcon2.png";

const Signin = () => {
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [loginSuccess, setLoginSuccess] = useState(true)

    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            loginEmailPassword();
        }
    }

    const loginEmailPassword = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, pass)
            window.location.href = "/home";
        }
        catch (error) {
            setLoginSuccess(false);
        }
    }

    const loginGooglePopUp = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider)
            .then((result) => {
                window.location.href = "/home";
            }).catch((error) => {
                // Handle Errors here.
                //const errorCode = error.code;
                //const errorMessage = error.message;
                // The email of the user's account used.
                //const email = error.customData.email;
                // The AuthCredential type that was used.
                GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }
    return (
        <>
            <Helmet>
                <title>
                    MyInfluency | Login
                </title>
            </Helmet>
            <div className="grid grid-cols-1 md:grid-cols-3 items-center h-screen">
                <div className="hidden md:flex md:flex-col h-full">
                    <img src={LoginIcon1} alt="Login Image1" className="md:w-1/2" />
                </div>

                <div className="flex flex-col p-20 bg-white rounded-xl md:shadow-2xl 2xl:mx-20">
                    <img src={logoGif} alt="My Influency" className="object-scale-down w-64 mx-auto mb-8" />
                    {(loginSuccess)
                        ? <></>
                        : <h2 className="text-red-500 text-center font-poppins text-base">Invalid password/username! Try again!</h2>
                    }
                    <div className="flex flex-col ">
                        <label className="text-left px-4 py-2 font-poppins text-black" htmlFor="email">Email:</label>
                        <input className="p-2 mb-4 border-b-2 border-mi-green" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="your_email@gmail.com" required="required" onKeyPress={handleKeypress}></input>
                        <label className="text-left px-4 py-2 font-poppins text-black" htmlFor="password">Password:</label>
                        <input className="p-2 border-b-2 border-mi-green" value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeholder="********" onKeyPress={handleKeypress}></input>
                        <button className="m-2 mt-8 border-0 rounded-lg p-4 cursor-pointer text-white font-poppins shadow-2xl bg-mi-green transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-200" onClick={loginEmailPassword} >Log In</button>
                    </div>
                    <div className="mx-auto text-gray-500 font-poppins">or</div>
                    <div className="mx-auto my-4">
                        <GoogleButton type='dark' onClick={loginGooglePopUp} />
                    </div>
                    <div className="flex flex-row gap-2 bg-white rounded-xl w-fit p-4 mx-auto">
                        <h2 className="text-center text-base text-gray-600 font-poppins">Don't have an account?</h2>
                        <a href="/create_account" className="text-center text-mi-green underline">Join the Community!</a>
                    </div>
                    <div className="flex flex-row gap-2 bg-white rounded-xl w-full p-4">
                        <a href="/password_recovery" className="w-full text-center text-mi-green underline">Forgot your password?</a>
                    </div>
                </div>
                
                <div className="hidden md:flex md:flex-col h-full justify-end items-end">
                    <img src={LoginIcon2} alt="Login Image2" className="md:w-1/2" />
                </div>
            </div>
        </>
    );
}

export default Signin;